import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import AboutSocialMedia from '../components/about-social';
import downloadArrow from "../components/icons/download-arrow.png";
import Head from '../components/head';

import cs50x from '../components/certificates/cs50x.png';
import react from '../components/certificates/reactcert.png';
import cs50w from '../components/certificates/cs50w.png';

const AboutPage = () => {

    return (
        <>
            {/**<GatsbySeo
                description="Learn more about me, a Jr. Full Stack Web Developer with skills in ReactJS, Django, and Tailwind. Discover my passion for creating dynamic, user-friendly, and responsive web applications."
            /> */}
            <Layout>
                <Head
                    title='About Me'
                    description="Check out my training and my certificates."
                />
                <div className='about-container'>
                    <div className='about'>
                        <div className='about__title'>
                            <h2>About me</h2>
                        </div>
                        <div className='about__bio-section'>
                            <div className='about__bio'>
                                <p>My name is <span className='key-words'>Khalid El Fasely</span>, I'm a <span className='key-words'>Junior Full Stack Web Developer</span>, I live in Casablanca-Morocco.</p>
                                {/*  based in Morocco */}
                                <br />
                                <p>
                                    From the moment I produced "Hello World" in the console of my first application. I knew I
                                    was hooked into the world of software development. But software development has never
                                    been "just a job" for me, it's offered an engaging challenge to continually learn and
                                    improve my skills in creating high-quality software. What started with a simple "Hello
                                    World" has become a full-fledged passion that only gets more exciting as the years go
                                    by.
                                </p>
                                <a href='/Khalid_El_Fasely.pdf' className="download-cv" download>
                                    Download Resume
                                    <img src={downloadArrow} alt='download arrow' />
                                </a>
                            </div>
                            <div className='about__img'>
                                {/*<img src={photo} alt='me' />*/}
                                <div className='about__social'>
                                    <AboutSocialMedia />
                                    <div className='about__contact-me-link__container'>
                                        <Link className="about__contact-me-link" to='/contact'>Contact me</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about__certificates'>
                            <h3>Courses & Training</h3>
                            <div>
                                <div>
                                    <h4>CS50x</h4>
                                    <img src={cs50x} alt='cs50x certificate' />
                                </div>
                                <div>
                                    <h4>CS50w</h4>
                                    <img src={cs50w} alt='cs50w certificate' />
                                </div>
                                <div>
                                    <h4>React</h4>
                                    <img src={react} alt='react certificate' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default AboutPage;